.editor-class {
}

.wrapper-class {
}

.DraftEditor-editorContainer {
    border: 1px solid #dddddd;
    padding:5px 20px !important;
}

